
.page-title {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #333;
  }
  .steps-title{
    font-size: 1.5em;
    margin: 1rem 0;
  }
  .podcast {
    &-title {
      font-size: 2em;
      margin-bottom: 1rem;
    }
    &-subtitle {
      font-size: 1.5em;
      font-weight: 400;
      font-style: italic;
      color: #666;
      margin-bottom: 1rem;
    }
  }
  .description {
    font-size: 1.2em;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #666;
  }
  
  .steps {
    list-style-type: decimal;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  
  .steps li {
    font-size: 1.1em;
    line-height: 1.6;
    margin-bottom: 10px;
    color: #666;
  }
  
  .form-title {
    font-size: 2em;
    margin-bottom: 20px;
    color: #333;
  }
  
  .calendar-container {
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
