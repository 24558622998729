.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 20px 20px;
    text-align: left;
  }
  
  .page-title {
    font-size: 3em;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
  }
  .video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    margin-bottom: 20px;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .description {
    font-size: 1.2em;
    line-height: 1.6;
    margin-bottom: 40px;
    color: #666;
  }
  
  .cta-container {
    margin-top: 40px;
    text-align: center;
  }
  
  .cta-button {
    display: inline-block;
    padding: 15px 30px;
    font-size: 1.1em;
    color: #fe1132;
    border: solid 1px #fe1132;
    border-radius: 4px;
    text-decoration: none;
    transition: background-color 0.3s ease;
    :hover {
        background-color: #fe1132;
        color: #ffffff;
    }
  }
