@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-items: center;

  min-height: 100vh;
  color: #000000;
  font-family: Inter, sans-serif;
}
.reckless {
  font-family: Reckless Neue;
}
.App {
  margin: 0 auto;
}
a {
  color: #fe1132;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.cover {
  max-width: 300px;
  aspect-ratio: 1/1;
  margin: 0 auto;
}
.App {
  margin: 0 auto;
  text-align: center;
}

.navbar {
  padding: 10px 0;
}

.nav-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  li {
    margin: 0 15px;
  }
}

.nav-link {
  color: #fe1132;
  text-decoration: none;
  font-size: 1.1em;
  transition: color 0.3s ease;
  :hover {
    text-decoration: underline;
  }
}
